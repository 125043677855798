// src/components/Home.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap styles
import { useNavigate, Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Card from 'react-bootstrap/Card';
import { API_URL } from "../apiURL";


const HomeNew = () => {
  const navigate = useNavigate();
  const [forms, setForms] = useState([]);
  const [user, setUser] = useState("");


  const handleAdmitCardsClick = () => {
    navigate("/downloadAdmit");
  };
  const handleExit = async () => {
    console.log("LOGOUT....")
    //set cookie to empty
    navigate("/");
  }
  const handleNewForm = async () => {
    console.log("New Form")
    navigate("/newForm");
  }

  const handleProfileClick = async ()=>{
    console.log("Go to profile")
    navigate("/profile")
  }


  useEffect(() => {
    // Fetch the list of forms using Axios (replace the URL with your actual endpoint)
    axios.get(`${API_URL}/getAdmitList`)
      .then(response => {
        // Check if the response data is an array or an object
        console.log("Form list fetched")
        if( response.data){
          if(response.data.message){
            toast.error(response.data.message)
            navigate("/");
          }
          if (Array.isArray(response.data)) {
            setForms(response.data);
            if(response.data[0]){
              setUser(response.data[0].Phone)
            }else{
              setUser(response.data.Phone)
              console.log("no forms")
            }
            
          }
          else {
            setUser(response.data.Phone)
            console.error('Invalid response format:', response.data);
          }
      }
      })
      .catch(error => {
        console.error('Error fetching forms:', error);
        navigate("/");
      });
  }, []);
  

  return (
    <div >
      <div style={{ display: 'flex', justifyContent: 'center' , padding: '15px'}}>
        <img src="/logo_0.png" alt="Header Image" style={{ height: '100px' }} />
      </div>
      <img src="/menu-bg.png" alt="Header Image" style={{ width: '100%', height: 'auto' }} />
      <Navbar variant="dark" style={{ backgroundColor: '#4c7c7c'}}>
        <Container>
          <Navbar.Brand href="/home">Meghalaya Police Recruitment 2024</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link onClick={console.log("form page loaded")}>Admit Cards</Nav.Link>
            {
            //<Nav.Link onClick={handleAdmitCardsClick}>Download Admit Cards</Nav.Link>
            //<Nav.Link onClick={handleProfileClick}>Edit Profile</Nav.Link>
            }
          </Nav>
          <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            Signed in as: {user}
          </Navbar.Text>
        </Navbar.Collapse>
        </Container>
      </Navbar>

      <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#dee0d7', padding: '20px' , height: '100vh' }}>

      
      <Card className="shadow-sm" style={{ borderColor: "#000", maxWidth: "80rem", width: '100%', height: '50%' }}>
      <Card.Body>
      <center>
        <br></br>
        <h2 className="mb-4" style={{ fontWeight: 'bold' }}>Admit Cards</h2>
      </center>
      {forms.length === 0 ? (
        <p className="container mt-5" style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold'}}>No Applications were submitted</p>
      ) : (
        <div className="container mt-5">
          <Table striped bordered hover>
          <thead>
            <tr>
              <th>Rollno</th>
              <th>Group</th>
              <th>Center</th>
              <th>Pet Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {forms.map((form, index) => (
              <tr key={index}>
                <td>
                  <Link to={`/admit-details/${form.rollno}`}>
                    {form.rollno}
                  </Link>
                </td>
                <td>{form.group}</td>
                <td>{form.petcenter}</td>
                <td>{form.petdate}</td>
                <td>
                  <Button 
                    variant="primary"
                    style={{ backgroundColor: '#406666'}}
                    onClick={() => navigate(`/admit-details/${form.rollno}`)}
                  >
                    Download
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        
        </div>
      )}
        <div style={{ display: 'flex', justifyContent: 'center'}}>
          {//<Button onClick={handleNewForm}style={{ marginRight: '10px', backgroundColor: '#406666'}} variant="primary">Apply for a new Post</Button>{' '}
          }<Button onClick={handleExit} variant="danger">LOGOUT</Button>{' '}
        </div>
        
      </Card.Body>
        </Card>
        </div>
      
    </div>
  );
};

export default HomeNew;
