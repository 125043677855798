// src/components/Home.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap styles
import { useNavigate, Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


const AdmitDownload = () => {
  const navigate = useNavigate();

  const handleFormsClick = () => {
    navigate("/home");
  };
  const handleProfileClick = async ()=>{
    console.log("Go to profile")
    navigate("/profile")
  }
  
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center' , padding: '15px'}}>
        <img src="/logo_0.png" alt="Header Image" style={{ height: '100px' }} />
      </div>
      <img src="/menu-bg.png" alt="Header Image" style={{ width: '100%', height: 'auto' }} />
      <Navbar variant="dark" style={{ backgroundColor: '#4c7c7c'}}>
        <Container>
          <Navbar.Brand href="/home">Meghalaya Police Recruitment 2024</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link onClick={handleFormsClick}>Application Forms</Nav.Link>
            <Nav.Link onClick={console.log("admit click..")}>Download Admit Cards</Nav.Link>
            <Nav.Link onClick={handleProfileClick}>Profile</Nav.Link>
          </Nav>
        </Container>
      </Navbar>

      
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#dee0d7', padding: '20px', height:'100vh' }}>
        <div style={{ marginBottom: '20px' }}>
          <h2>Admit cards will be available for download here.  </h2>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="danger" onClick={handleFormsClick}>Go Back</Button>
        </div>
      </div>
        
    </div>
  );
};

export default AdmitDownload;
